export const state = () => ({
    name: 'Register for a FREE online Webinar',
    date: '21 May 2020',
    time: '6pm (60min)',
    rows: [{
        text: "What are the current IT industry trends."
    }, {
        text: "How the software industry roles are changing."
    }, {
        text: "How to adapt to changing hiring process."
    }, {
        text: "Why upgrading your skills is important."
    }, {
        text: "What skills are needed to grow in career."
    }, {
        text: "What steps you can take now to succeed."
    }]
})

export const mutations = {
    add(state, text) {
        state.list.push({
            text,
            done: false
        })
    },
    remove(state, {
        todo
    }) {
        state.list.splice(state.list.indexOf(todo), 1)
    },
    toggle(state, todo) {
        todo.done = !todo.done
    }
}
