
export default {
  name: 'Navigation',
  data() {
    return { cddLogo: require('../assets/white-logo.png') };
  },
  methods: {
    isActive: function (route) {
      return route == this.$route.path ? 'nav-item active' : 'nav-item';
    },
  },
}
