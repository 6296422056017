export const state = () => ({
    name: 'Get ahead with Typescript',
    description: 'How typescript helps you become a better developer.',
    date: '26 Jan 2020',
    time: '6pm (90min)',
    rows: [{
        text: "Why types matter and why should you care."
    }, {
        text: "How typescript fixes broken javascript."
    }, {
        text: "How typescript helps you become better dev."
    }, {
        text: "Improved quality of software with typescript"
    }, {
        text: "Object oriented programming with Typescript."
    }, {
        text: "How to grow your skills and become an expert."
    }]
})

export const mutations = {
    add(state, text) {
        state.list.push({
            text,
            done: false
        })
    },
    remove(state, {
        todo
    }) {
        state.list.splice(state.list.indexOf(todo), 1)
    },
    toggle(state, todo) {
        todo.done = !todo.done
    }
}
