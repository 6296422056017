
export const state = () => ({
  items: [
    {
      q: "Why are CDD programs online?",
      a: [
        "As CDD programs are designed for people already experienced in programming, we assume that you might already have a full time or part time job or working on your other career related activities; therefore online schedule works perfect for our students."
      ]
    },
    {
      q: "What do I need to attend the CDD program?",
      a: [
        "You would need a laptop with internet connection fast enough to attend video conferences. We’ll provide you with the list of softwares you would need to code for the tasks provided during the program."
      ]
    },
    {
      q: "I am a beginner in programming, is there a program for me?",
      a: [
        "We are glad that you decided to learn programing and we are surely happy to help you learn the right skills.",
        "We would like to personally consult you for the right program that suits your skill level, so please contact us at contact@codingdeepdive.com"
      ]
    },
    {
      q: "Will I get a certificate when I finish a CDD program?",
      a: [
        "Yes!! You will get a reference letter and a certificate of program completion."
      ]
    },
    {
      q: "How many hours do I have to dedicate every week?",
      a: [
        "As many as you can! The programs have scheduled online sessions which you must attend to grasp the content of the course. Other than the scheduled online classes, you are given tasks to solve by yourself which require you to work independently during the week. The more time you spend the better results you will have."
      ]
    },
    {
      q: "How is CDD different from any other coding bootcamp?",
      a: [
        "Coding Deep Dive, is not a coding bootcamp. Coding Deep Dive is more like a mentorship program where you learn from industry experts and focus on skills that matter in the industry.",
        "CDD helps you understand things in-depth rather than just getting exposed to them. We teach you real skills which you would need in a real job or to create an amazing product of your own."
      ]
    },
    {
      q: "How do you decide who gets accepted?",
      a: [
        "We consider candidates who fit perfectly to the program’s requirements and are extremely motivated with a strong desire to learn. Sadly, we know many candidates are a perfect fit for the program but we still cannot accept them due to the limited number of seats in the program."
      ]
    }
  ]
});

export const mutations = {
  add(state, text) {
    state.list.push({
      text,
      done: false
    });
  },
  remove(state, {
    todo
  }) {
    state.list.splice(state.list.indexOf(todo), 1);
  },
  toggle(state, todo) {
    todo.done = !todo.done;
  }
};
