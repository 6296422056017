
export const state = () => ({
  profiles: [
    {
      name: "Imad",
      company: "Parity Technology",
      testimony:
        "I took this course after a 12 week intense coding bootcamp and was very much surprised as to how much stuff was left off during the course. Coding Deep Dive fills in those exact gaps. From understanding programming fundamentals to interview prep - CDD course will prepare you for everything a programmer needs. A lot of the covered topics repeatedly came up during job interviews, and I was able to get an edge among other candidates due to knowledge acquired during this course.",
      photo: require("../assets/alumni/imad.png"),
      isStory: true
    },
    {
      name: "Francesca",
      company: "FinLink GmbH",
      testimony:
        "Coming from a non-technical background and having only basic programming experience, I had many questions about coding and design. CDD helped me understand how to write clean and structured code. I would recommend CDD to everyone who needs to deepen their knowledge of programming and wants to learn how good developers think.",
      photo: require("../assets/alumni/fra.png"),
      isStory: true
    },
    {
      name: "Steve",
      company: "Fatchip GmbH",
      testimony:
        "Joining CDD course helped me realize what I don’t know. Talking to people from Industry with real world experience does give you another perspective on software development.",
      photo: require("../assets/alumni/steve.png"),
      isStory: true
    },
    {
      name: "Julia",
      testimony:
        "As a front-end developer I felt very confused about how I should organize my app. CDD helped me with concepts of software design that I could also apply in the front end. CDD made me realize that design is important everywhere.",
      photo: require("../assets/alumni/julia.png")
    },
    {
      name: "Harold",
      testimony:
        "I needed guidance to get better at my skills and grow in my career. After getting help from CDD, now I am able to see beyond the code and delve deeper into the design aspects and scalability of my projects.",
      photo: require("../assets/alumni/har.png")
    },
    {
      name: "Sofia",
      testimony:
        "Being a person who doesn’t have a strong computer science background, CDD was where I got the mentorship for my personal products. Being an entrepreneur, it was absolutely necessary for me to get a deeper understanding of how to build my products correctly and CDD was the perfect place to learn about it.",
      photo: require("../assets/alumni/sof.png")
    },

    {
      name: "Ochir",
      testimony:
        "I always had problems with server side design. That’s the time I met guys at CDD and they helped me understand it very well. It was a good investment in my skills.",
      photo: require("../assets/alumni/och.png")
    },
    {
      name: "Miguel",
      testimony:
        "I needed help with VueJS and frontend design patterns. I found Coding Deep Dive through a friend who earlier was a student here. After joining CDD course I learnt skills that I could easily translate to frontend design and development of the e-commerce product at my job.",
      photo: require("../assets/alumni/miguel.jpeg")
    }
  ]
});

export const mutations = {
  add(state, text) {
    state.list.push({
      text,
      done: false
    });
  },
  remove(state, {
    todo
  }) {
    state.list.splice(state.list.indexOf(todo), 1);
  },
  toggle(state, todo) {
  }
};

