import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=6f6f1796&scoped=true&lang=html"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=6f6f1796&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6f1796",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/Users/ravi/Projects/VSCode/coding-deep-dive/components/Footer.vue').default})
